<template>
  <div class="confirmation__summary">
    <h3 class="text-subtitle-1 font-weight-bold mb-3">
      {{ title }}
    </h3>
    <div class="cw-application__table">
      <table>
        <tbody>
          <tr v-for="item in applicationSummary" :key="item.name">
            <td>{{ item.name }}</td>
            <td class="text-right font-weight-medium">
              <template>
                {{ item.value }}
              </template>
            </td>
          </tr>
          <tr v-for="item in withdrawalSummary" :key="item.name">
            <td class="align-top">
              {{ item.name }}
            </td>
            <td class="text-right font-weight-medium">
              <div>
                <div>
                  {{ item.value }}
                </div>
                <v-btn
                  id="open-first-withdrawal-dialog"
                  color="primary"
                  outlined
                  small
                  text
                  @click="
                    $bus.$emit('PAYMENT_SCHEDULE_DIALOG__OPEN');
                    $eventLogger.clickEvent($event);
                  "
                >
                  <translate>
                    Change
                  </translate>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <v-alert
      v-if="showPaymentInfo"
      border="left"
      class="mb-0 mt-6"
      color="info"
      icon="info"
      dense
      text
    >
      <p class="mb-0">
        <translate>
          The money will be credited to your account as soon as possible.
        </translate>
      </p>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwConfirmationSummary',

  props: {
    showPaymentInfo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      applicationSummary: 'application/getApplicationSummary',
      withdrawalSummary: 'application/getWithdrawalSummary',
      product: 'application/getProduct',
    }),

    title() {
      const text = this.$gettext('Summary');
      return `${text} - ${this.product.productDisplayName}`;
    },
  },
};
</script>
